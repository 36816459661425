<template>
  <nav class="absolute top-0 right-0 m-3 lg:m-6 flex z-40 bg-white">
    <div
      v-for="lang in languages"
      :key="lang.abbreviation"
      @click.prevent="setLang(lang)"
      class="border border-gray-100 uppercase p-2 text-sm cursor-pointer hover:bg-gray-200"
      :class="{
        'border-gray-400 font-bold':
          activeLanguage.abbreviation == lang.abbreviation
      }"
    >
      {{ lang.abbreviation }}
    </div>
  </nav>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "SelectLanguage",
  components: {},
  filters: {},
  props: {},
  data: function() {
    return {};
  },
  computed: {
    ...mapState({
      languages: state => state.Settings.languages,
      activeLanguage: state => state.Settings.activeLanguage
    })
    // ...mapGetters({
    //   item: "namespace/item"
    // })
  },
  watch: {},
  async created() {},
  async mounted() {},
  methods: {
    setLang(lang) {
      this.$store.commit("Settings/SET_ACTIVE_LANG", lang);
      localStorage.setItem("minse_language", JSON.stringify(lang));
    }
  }
};
</script>

<style scoped lang="scss"></style>
