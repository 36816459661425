<template>
  <div class="w-full h-screen shadow-lg z-10 relative overflow-auto lg:px-6">
    <div
      v-if="!loading"
      class="container page pt-20 lg:pt-16 pb-24 px-6 lg:px-0"
    >
      <div v-html="langs[this.lang.abbreviation].content"></div>
    </div>

    <div v-else class="w-full h-full flex justify-center items-center">
      <DotLoading dotColor="#25899b" />
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import DotLoading from "@/components/DotLoading";

export default {
  name: "PageView",
  components: {
    DotLoading
  },
  filters: {},
  props: {},
  data: function() {
    return {
      loading: true,
      page: null,
      langs: null,
      contentWithGlossary: null
    };
  },
  computed: {
    ...mapState({
      lang: state => state.Settings.activeLanguage,
      glossary: state => state.Glossary.list,
      selectedEntry: state => state.Glossary.selectedEntry
    })

    // ...mapGetters({
    //   item: "namespace/item"
    // })
  },
  watch: {},
  async created() {
    const page = await this.$store.dispatch(
      "Pages/single",
      "WwwQ1jiFUO0PjmHUbhuw"
    );
    const langs = await this.$store.dispatch(
      "Pages/langs",
      "WwwQ1jiFUO0PjmHUbhuw"
    );

    this.page = page;
    this.langs = langs;

    // this.applyGlossary();
    this.loading = false;

    // setTimeout para dar tempo do DOM montar
    setTimeout(() => {
      // busca os elementos que adicionamos a SPAN de glossario
      const classname = document.getElementsByClassName("glossaryEntry");

      var applyEntry = event => {
        var attribute = event.currentTarget.getAttribute("data-glossary");
        this.$store.commit("Glossary/SET_SELECTED_ENTRY", {
          cordX: event.pageX,
          cordY: event.pageY,
          entryId: attribute
        });
      };

      // pra cada um deles, adiciona um listener pra clique, pra pessoa ler o glossario dele
      for (var i = 0; i < classname.length; i++) {
        classname[i].addEventListener("click", applyEntry, false);
      }
    }, 500);
  },
  async mounted() {},
  methods: {}
};
</script>

<style scoped lang="scss">
.container {
  max-width: 1024px;
}
</style>

<style lang="scss">
.page {
  h1 {
    font-size: 36px;
    font-weight: 700;
    margin-bottom: 1.5rem;
    line-height: 42px;
  }

  h2 {
    font-size: 26px;
    font-weight: 700;
    margin-bottom: 1.5rem;
  }

  h3 {
    font-size: 20px;
    font-weight: 700;
    margin-bottom: 1.5rem;
  }

  p {
    font-size: 1.4rem;
    margin-bottom: 1rem;
    text-indent: 4rem;
  }

  hr {
    margin: 2rem 0;
    border-color: #ccc;
  }

  img {
    margin: 0.5rem 0;
  }

  h1 + p,
  h2 + p,
  h3 + p,
  h4 + p,
  h5 + p,
  blockquote p,
  ul li p,
  ol li p {
    text-indent: 0;
  }

  blockquote {
    padding: 1rem 0 1rem 4rem;
    font-size: 1.3rem;

    p {
      font-size: 1.3rem;
    }
  }

  ul {
    list-style: circle;
    text-indent: 0;
    margin: 2rem 0 2rem 3.8rem;
    padding: 0;

    li {
      font-family: Georgia, Times, "Times New Roman", serif;
      font-size: 1.4rem;
    }
  }

  ol {
    list-style: decimal;
    text-indent: 0;
    margin: 2rem 0 2rem 3.8rem;
    padding: 0;

    li {
      font-family: Georgia, Times, "Times New Roman", serif;
      font-size: 1.4rem;
    }
  }

  table {
    width: calc(90% - 4rem);
    margin: 2rem 0 2rem 4rem;
  }

  table,
  tr,
  td,
  th {
    border: 1px solid #ccc;
    padding: 6px;

    p {
      font-size: 1rem;
      text-indent: 0;
    }
  }

  th,
  th p {
    font-family: "Poppins", sans-serif;
    text-align: left;
  }
}

.glossaryEntry {
  background: #95ddea;
  padding: 0 4px;
  cursor: pointer;
  border-radius: 3px;

  &:hover {
    background: #66c7d9;
  }
}

.iframe-container {
  overflow: hidden;
  padding-top: 56.25%;
  position: relative;
  background: #000;
}

.iframe-container iframe {
  position: absolute;
  top: 0;
  left: 0;
  border: 0;
  width: 100%;
  height: 100%;
}
</style>
