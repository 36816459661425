<template>
  <div class="relative z-60">
    <div
      @click.prevent="close()"
      class="tab-bg fixed top-0 right-0 w-screen h-screen cursor-pointer"
    ></div>

    <div
      class="glossaryTab bg-white fixed top-0 right-0 p-12 w-full md:w-1/2 h-screen overflow-auto flex flex-wrap justify-center"
    >
      <div
        @click.prevent="close()"
        class="border px-3 py-2 cursor-pointer absolute top-0 right-0 m-6"
      >
        <img src="/icons/close.svg" class="w-6 h-6" alt="" />
      </div>
      <div v-if="!loading">
        <div v-html="entryContent[lang.abbreviation].content"></div>

        <HowQuote
          :title="entryContent[lang.abbreviation].title"
          :updateDate="
            entryContent[lang.abbreviation]._updateDate
              ? entryContent[lang.abbreviation]._updateDate
              : entryContent[lang.abbreviation]._createDate
          "
        />
      </div>
      <div v-else class="flex h-screen w-full items-center justify-center">
        <DotLoading dotColor="#25899b" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import DotLoading from "@/components/DotLoading";
import HowQuote from "@/components/HowQuote";

export default {
  name: "GlossaryModal",
  components: {
    DotLoading,
    HowQuote
  },
  filters: {},
  props: {},
  data: function() {
    return {
      loading: true,
      entryContent: null,
      message: null
    };
  },
  computed: {
    ...mapState({
      lang: state => state.Settings.activeLanguage,
      entry: state => state.Glossary.selectedEntry
    })
    // ...mapGetters({
    //   item: "namespace/item"
    // })
  },
  watch: {},
  async created() {
    this.loading = true;
    this.message = null;
    this.entryContent = null;

    const entryContent = await this.$store.dispatch(
      "Glossary/langs",
      this.entry.entryId
    );

    if (entryContent) {
      this.entryContent = entryContent;
    } else {
      this.message = "Ops, não foi possível carregar o verbete.";
    }

    this.loading = false;
  },
  async mounted() {},
  methods: {
    close() {
      this.$store.commit("Glossary/SET_SELECTED_ENTRY", {
        cordX: 0,
        cordY: 0,
        entryId: null
      });
    }
  }
};
</script>

<style lang="scss">
.glossaryTab {
  h1 {
    font-size: 36px;
    font-weight: 700;
    margin-bottom: 1.5rem;
    line-height: 42px;
  }

  h2 {
    font-size: 26px;
    font-weight: 700;
    margin-bottom: 1.5rem;
  }

  h3 {
    font-size: 20px;
    font-weight: 700;
    margin-bottom: 1.5rem;
  }

  p {
    font-size: 1.4rem;
    margin-bottom: 1rem;
    text-indent: 4rem;
  }

  h1 + p,
  h2 + p,
  h3 + p,
  h4 + p,
  h5 + p,
  ul + p,
  ol + p {
    text-indent: 0;
  }
}

.tab-bg {
  background: rgba(0, 0, 0, 0.75);
}
</style>
