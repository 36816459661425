import Vue from "vue";
import Vuex from "vuex";

import _BaseStore from "./_BaseStore";
import User from "./User";
import Pages from "./Pages";
import Glossary from "./Glossary";
import Classes from "./Classes";
import Images from "./Images";
import Settings from "./Settings";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    _BaseStore,
    User,
    Pages,
    Glossary,
    Classes,
    Images,
    Settings
  },
  state: {},
  mutations: {},
  actions: {}
});
