import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import "firebase/storage";

const config = {
  apiKey: "AIzaSyDIiDaF5r5PrSvy23AbOeFjvCKXEOuV5Lg",
  authDomain: "minute-semeiotic.firebaseapp.com",
  databaseURL: "https://minute-semeiotic.firebaseio.com",
  projectId: "minute-semeiotic",
  storageBucket: "minute-semeiotic.appspot.com",
  messagingSenderId: "68200101016",
  appId: "1:68200101016:web:08047e29f31f3093fa4ced",
  measurementId: "G-4SFN8Q1LQT"
};

!firebase.apps.length ? firebase.initializeApp(config) : firebase.app();

const firestore = firebase.firestore();

export const GoogleProvider = new firebase.auth.GoogleAuthProvider();
export const Auth = firebase.auth();
export const Database = firestore;
export const Storage = firebase.storage();
export const Firebase = firebase;
export default firebase;
