<template>
  <div>
    <header class="bg-white px-4 py-2 w-full shadow">
      <div class="container flex justify-between items-center">
        <h3 class="text-xl">
          <router-link to="/gerenciador/painel">Gerenciador</router-link>
          <a
            href="/"
            target="_blank"
            class="text-xs ml-2 underline text-blue-600 hover:text-blue-800"
            >Abrir site</a
          >
        </h3>
        <div
          @click.prevent="logout()"
          class="cursor-pointer text-gray-700 hover:text-gray-900 hover:underline"
        >
          Logout
        </div>
      </div>
    </header>

    <div class="container flex mt-4 admin-menu">
      <router-link
        to="/gerenciador/paginas"
        class="bg-white block mr-2 rounded shadow hover:shadow-lg transition-shadow w-full max-w-xs pt-6 pb-2 px-4 text-2xl poppins font-extrabold text-gray-700"
      >
        Páginas
      </router-link>

      <router-link
        to="/gerenciador/classes"
        class="bg-white block mr-2 rounded shadow hover:shadow-lg transition-shadow w-full max-w-xs pt-6 pb-2 px-4 text-2xl poppins font-extrabold text-gray-700"
      >
        Classes
      </router-link>

      <router-link
        to="/gerenciador/glossario"
        class="bg-white block mr-2 rounded shadow hover:shadow-lg transition-shadow w-full max-w-xs pt-6 pb-2 px-4 text-2xl poppins font-extrabold text-gray-700"
      >
        Glossário
      </router-link>

      <router-link
        to="/gerenciador/configuracoes"
        class="bg-white block mr-2 rounded shadow hover:shadow-lg transition-shadow w-full max-w-xs pt-6 pb-2 px-4 text-2xl poppins font-extrabold text-gray-700"
      >
        Config.
      </router-link>
    </div>
  </div>
</template>

<script>
//import { mapState, mapGetters  } from 'vuex';

export default {
  name: "AdminHeader",
  components: {},
  filters: {},
  props: {},
  data: function() {
    return {};
  },
  computed: {
    // ...mapState({
    //   item: state => state.namespace.item
    // }),
    // ...mapGetters({
    //   item: "namespace/item"
    // })
  },
  watch: {},
  async created() {},
  async mounted() {},
  methods: {
    async logout() {
      const response = this.$store.dispatch("User/SignOut");

      if (response) {
        this.$router.push("/gerenciador/login");
      } else {
        alert("Ops, aconteceu algum erro!");
      }
    }
  }
};
</script>

<style scoped lang="scss"></style>
