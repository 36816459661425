<template>
  <div>
    <div
      @click.prevent="show = !show"
      class="absolute top-0 left-0 m-3 flex z-50 border border-gray-400 px-3 py-2 cursor-pointer hover:bg-gray-300 bg-white"
    >
      Menu
    </div>
    <div
      class="w-full md:w-1/2 lg:w-72 absolute lg:relative menu h-screen z-50 shadow-lg lg:shadow-none overflow-auto"
      :class="show ? '' : 'hidden lg:block'"
    >
      <h1 v-if="lang" class="logo mx-4 my-6">
        <router-link v-if="lang.abbreviation == 'pt'" to="/">
          Semiótica <br />
          <span>•</span>
          <strong>Minuta</strong>
        </router-link>
        <router-link v-else-if="lang.abbreviation == 'es'" to="/">
          Semiótica <br />
          <span>•</span>
          <strong>Minuta</strong>
        </router-link>
        <router-link v-else-if="lang.abbreviation == 'it'" to="/">
          Semiotica <br />
          <span>•</span>
          <strong>Minuta</strong>
        </router-link>
        <router-link v-else to="/">
          Minute <br />
          <span>•</span>
          <strong>Semeiotic</strong>
        </router-link>
      </h1>

      <h3
        v-if="lang && lang.abbreviation"
        class="mt-10 text-white font-bold mb-3 pl-4"
      >
        {{ labels.tables }}
      </h3>
      <nav class="menuItens" v-if="lang && lang.abbreviation">
        <ul class="border-t border-white">
          <li>
            <router-link
              to="/ten-classes"
              class="block border-b border-white text-white px-4 py-3 opacity-75 hover:opacity-100"
              >{{ labels.table10 }}</router-link
            >
          </li>
          <li>
            <router-link
              to="/sixty-six-classes"
              class="block border-b border-white text-white px-4 py-3 opacity-75 hover:opacity-100"
              >{{ labels.table66 }}</router-link
            >
          </li>
          <li v-for="page in tablesPages" :key="page._id">
            <router-link
              :to="`/${page._id}`"
              class="block border-b border-white text-white px-4 py-3 opacity-75 hover:opacity-100"
              >{{ page.langs[lang.abbreviation] }}</router-link
            >
          </li>
        </ul>
      </nav>

      <h3
        v-if="lang && lang.abbreviation"
        class="mt-10 text-white font-bold mb-3 pl-4"
      >
        {{ labels.minute }} {{ labels.semiotic }}
      </h3>

      <nav class="menuItens" v-if="lang && lang.abbreviation">
        <ul class="border-t border-white">
          <li v-for="page in contentPages" :key="page._id">
            <router-link
              :to="`/${page._id}`"
              class="block border-b border-white text-white px-4 py-3 opacity-75 hover:opacity-100"
              >{{ page.langs[lang.abbreviation] }}</router-link
            >
          </li>
        </ul>
      </nav>

      <h3
        v-if="lang && lang.abbreviation && institutionalPages.length > 0"
        class="mt-2 text-white font-bold mt-10 mb-3 pl-4"
      >
        {{ labels.institutional }}
      </h3>
      <nav class="menuItens mb-20" v-if="lang && lang.abbreviation">
        <ul class="border-t border-white">
          <li>
            <router-link
              to="/glossary"
              class="block border-b border-white text-white px-4 py-3 opacity-75 hover:opacity-100"
              >{{ labels.glossary }}</router-link
            >
          </li>

          <li v-for="page in institutionalPages" :key="page._id">
            <router-link
              :to="`/${page._id}`"
              class="block border-b border-white text-white px-4 py-3 opacity-75 hover:opacity-100"
              >{{ page.langs[lang.abbreviation] }}</router-link
            >
          </li>
        </ul>
      </nav>

      <div
        @click.prevent="show = false"
        class="absolute top-0 right-0 text-white m-3 flex z-50 border lg:hidden border-gray-400 px-3 py-2 cursor-pointer"
      >
        fechar
      </div>
    </div>

    <SelectLanguage />
  </div>
</template>

<script>
import { mapState } from "vuex";
import labels from "@/i18n/labels";

import SelectLanguage from "@/components/SelectLanguage";

export default {
  name: "SideMenu",
  components: {
    SelectLanguage
  },
  filters: {},
  props: {},
  data: function() {
    return {
      show: false,
      open: null,

      tables: {
        pt: "Tabelas e Diagramas",
        en: "Tables and Diagrams"
      },

      TenClasses: {
        pt: "Dez classes de signos de Peirce",
        en: "Peirce's ten classes of signs"
      },

      SixtySixClasses: {
        pt: "Tabela periódica das Classes de Signos",
        en: "Periodic table of Classes of Signs"
      }
    };
  },
  computed: {
    ...mapState({
      pages: state => state.Pages.list,
      lang: state => state.Settings.activeLanguage
    }),

    labels() {
      return labels[this.lang.abbreviation]
        ? labels[this.lang.abbreviation]
        : labels["en"];
    },

    contentPages() {
      if (this.pages.length > 0) {
        return [...this.pages]
          .filter(page => page.type == "content")
          .sort((a, b) => a.order - b.order);
      } else {
        return [];
      }
    },

    tablesPages() {
      if (this.pages.length > 0) {
        return [...this.pages]
          .filter(page => page.type == "tables")
          .sort((a, b) => a.order - b.order);
      } else {
        return [];
      }
    },

    institutionalPages() {
      if (this.pages.length > 0) {
        return [...this.pages]
          .filter(page => page.type == "institutional")
          .sort((a, b) => a.order - b.order);
      } else {
        return [];
      }
    }
    // ...mapGetters({
    //   item: "namespace/item"
    // })
  },
  watch: {
    $route() {
      this.show = false;
    }
  },
  async created() {
    await this.$store.dispatch("Pages/list");
  },
  async mounted() {},
  methods: {}
};
</script>

<style scoped lang="scss">
.menu {
  background: rgb(37, 137, 155);
  background: linear-gradient(
    160deg,
    rgba(37, 137, 155, 1) 0%,
    rgba(26, 95, 112, 1) 56%,
    rgba(9, 34, 49, 1) 100%
  );

  .menuItens .router-link-active {
    opacity: 1 !important;
    font-weight: 500;
  }

  .sub {
    background: #007588;
  }
}

.logo {
  font-size: 2.4rem;
  line-height: 2rem;
  font-family: "Roboto", sans-serif;
  font-style: italic;
  font-weight: 200;
  text-transform: lowercase;
  color: #fff;
  padding: 0.5rem;

  span {
    font-size: 48px;
    position: relative;
    top: 5px;
    padding-right: 10px;
  }

  strong {
    font-weight: 300;
  }

  a {
    color: #fff;
    text-decoration: none;
  }
}
</style>
