import _base from "@/api/_base";
import dayjs from "dayjs";

const _baseStore = {
  namespaced: true,
  state: {
    list: []
  },
  mutations: {
    SET_LIST(state, payload) {
      state.list = payload;
    }
  },
  actions: {
    async list({ commit }) {
      try {
        const response = await _base.list();
        commit("SET_LIST", response);
        return response;
      } catch (err) {
        console.log(err);
      }
    },

    async single(_, docId) {
      try {
        const response = await _base.get(docId);

        response.startingDate = dayjs
          .unix(response.startingDate / 1000)
          .format();

        response.endingDate = dayjs.unix(response.endingDate / 1000).format();
        return response;
      } catch (err) {
        console.log(err);
      }
    },

    async add({ dispatch }, itemData) {
      try {
        const response = await _base.add(itemData);

        if (response._status) {
          await dispatch("list");
        }
        return response;
      } catch (err) {
        console.log(err);
      }
    },

    async update({ dispatch }, itemData) {
      try {
        const response = await _base.update(itemData);

        if (response._status) {
          await dispatch("list");
        }

        return response;
      } catch (err) {
        console.log(err);
      }
    },

    async remove({ dispatch }, _baseId) {
      try {
        const response = await _base.remove(_baseId);

        if (response._status) {
          await dispatch("list");
        }

        return response;
      } catch (err) {
        console.log(err);
      }
    }
  }
};

export default _baseStore;
