import { Database } from "@/services/firebase";
import dayjs from "dayjs";

export default {
  async list() {
    try {
      const querySnapshot = await Database.collection("glossary").get();
      const list = [];
      querySnapshot.forEach(function(doc) {
        const docObj = {
          ...doc.data(),
          _id: doc.id
        };
        list.push(docObj);
      });
      return list;
    } catch (err) {
      return {
        _status: false,
        _message: "Error api/glossary.js: " + err
      };
    }
  },

  async get(docId) {
    try {
      const doc = await Database.collection("glossary")
        .doc(docId)
        .get();
      if (doc.exists)
        return {
          ...doc.data(),
          _id: doc.id,
          _status: true,
          message: "Document found"
        };
      else
        return {
          _status: false,
          _message: "Document not found"
        };
    } catch (err) {
      return {
        _status: false,
        _message: "Error api/glossary.js: " + err
      };
    }
  },

  async child(docId) {
    try {
      const querySnapshot = await Database.collection("glossary")
        .doc(docId)
        .collection("langs")
        .get();

      const list = {};
      querySnapshot.forEach(function(doc) {
        const docObj = {
          ...doc.data(),
          _id: doc.id
        };
        list[doc.id] = docObj;
      });
      return list;
    } catch (err) {
      console.log("Error api/glossary.js child(): ", err);
      return {
        _status: false,
        _message: "Error api/glossary.js: " + err
      };
    }
  },

  async add(entry, langs) {
    try {
      const docRef = await Database.collection("glossary").doc();
      docRef.set({
        _id: docRef.id,
        _createDate: dayjs().format(),
        ...entry
      });

      const langsArray = Object.keys(langs);

      for (const lang of langsArray) {
        const langRef = await Database.collection("glossary")
          .doc(docRef.id)
          .collection("langs")
          .doc(lang);

        const langContent = {
          _id: langRef.id,
          _createDate: dayjs().format(),
          ...langs[lang]
        };
        langRef.set({ ...langContent });
      }

      return {
        _status: true,
        _message: "Document created."
      };
    } catch (err) {
      return {
        _status: false,
        _message: "Error api/glossary.js: " + err
      };
    }
  },

  async update(entry, langs) {
    try {
      const docRef = await Database.collection("glossary").doc(entry._id);
      docRef.set({
        _updateDate: dayjs().format(),
        ...entry
      });

      const langsArray = Object.keys(langs);

      for (const lang of langsArray) {
        const langRef = await Database.collection("glossary")
          .doc(docRef.id)
          .collection("langs")
          .doc(lang);

        const langContent = {
          _id: langRef.id,
          _updateDate: dayjs().format(),
          ...langs[lang]
        };
        langRef.set({ ...langContent });
      }

      return {
        _status: true,
        _message: "Document updated."
      };
    } catch (err) {
      return {
        _status: false,
        _message: "Error api/glossary.js: " + err
      };
    }
  },

  async remove(docId) {
    try {
      await Database.collection("glossary")
        .doc(docId)
        .delete();
      return {
        _status: true,
        _message: "Document deleted."
      };
    } catch (err) {
      return {
        _status: false,
        _message: "Error api/glossary.js: " + err
      };
    }
  }
};
