import Glossary from "@/api/glossary";

const GlossaryStore = {
  namespaced: true,
  state: {
    list: [],

    selectedEntry: {
      cordX: 0,
      cordY: 0,
      entryId: null
    }
  },
  mutations: {
    SET_LIST(state, payload) {
      state.list = payload;
    },

    SET_SELECTED_ENTRY(state, payload) {
      state.selectedEntry = payload;
    }
  },
  actions: {
    async list({ commit }) {
      try {
        const response = await Glossary.list();
        commit("SET_LIST", response);
        return response;
      } catch (err) {
        console.log(err);
      }
    },

    async single(_, docId) {
      try {
        const response = await Glossary.get(docId);
        return response;
      } catch (err) {
        console.log(err);
      }
    },

    async langs(_, docId) {
      try {
        const response = await Glossary.child(docId);
        return response;
      } catch (err) {
        console.log(err);
      }
    },

    async add({ dispatch }, [entryData, langsData]) {
      try {
        const response = await Glossary.add(entryData, langsData);

        if (response._status) {
          await dispatch("list");
        }
        return response;
      } catch (err) {
        console.log(err);
      }
    },

    async update({ dispatch }, [entryData, langsData]) {
      try {
        const response = await Glossary.update(entryData, langsData);

        if (response._status) {
          await dispatch("list");
        }

        return response;
      } catch (err) {
        console.log(err);
      }
    },

    async remove({ dispatch }, GlossaryId) {
      try {
        const response = await Glossary.remove(GlossaryId);

        if (response._status) {
          await dispatch("list");
        }

        return response;
      } catch (err) {
        console.log(err);
      }
    }
  }
};

export default GlossaryStore;
