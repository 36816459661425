import Vue from "vue";
import VueRouter from "vue-router";
import { Auth } from "../services/firebase";

import Home from "../views/Home.vue";

import AdminHeader from "../components/AdminHeader";
import SideMenu from "../components/SideMenu";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "home",
    meta: {
      requiresAuth: false
    },
    components: {
      sidemenu: SideMenu,
      default: Home
    }
  },

  {
    path: "/gerenciador",
    redirect: "/gerenciador/painel"
  },
  {
    path: "/admin",
    redirect: "/gerenciador/painel"
  },
  {
    path: "/administrador",
    redirect: "/gerenciador/painel"
  },
  {
    path: "/login",
    redirect: "/gerenciador/login"
  },
  {
    path: "/gerenciador/login",
    name: "Login",
    meta: {
      requiresAuth: false
    },
    component: () =>
      import(/* webpackChunkName: "login" */ "../views/admin/Login.vue")
  },
  {
    path: "/gerenciador/painel",
    name: "Painel",
    meta: {
      requiresAuth: true
    },
    components: {
      header: AdminHeader,
      default: () =>
        import(/* webpackChunkName: "painel" */ "../views/admin/Index.vue")
    }
  },
  {
    path: "/gerenciador/paginas",
    name: "PagesAdmin",
    meta: {
      requiresAuth: true
    },
    components: {
      header: AdminHeader,
      default: () =>
        import(/* webpackChunkName: "pages" */ "../views/admin/pages/Index.vue")
    }
  },
  {
    path: "/gerenciador/paginas/:id",
    name: "PagesEdit",
    meta: {
      requiresAuth: true
    },
    components: {
      header: AdminHeader,
      default: () =>
        import(
          /* webpackChunkName: "pagesEdit" */ "../views/admin/pages/Form.vue"
        )
    }
  },
  {
    path: "/gerenciador/glossario",
    name: "Glossary",
    meta: {
      requiresAuth: true
    },
    components: {
      header: AdminHeader,
      default: () =>
        import(
          /* webpackChunkName: "glossary" */ "../views/admin/glossary/Index.vue"
        )
    }
  },
  {
    path: "/gerenciador/glossario/:id",
    name: "GlossaryEdit",
    meta: {
      requiresAuth: true
    },
    components: {
      header: AdminHeader,
      default: () =>
        import(
          /* webpackChunkName: "glossaryEdit" */ "../views/admin/glossary/Form.vue"
        )
    }
  },
  {
    path: "/gerenciador/classes",
    name: "Classes",
    meta: {
      requiresAuth: true
    },
    components: {
      header: AdminHeader,
      default: () =>
        import(
          /* webpackChunkName: "classes" */ "../views/admin/classes/Index.vue"
        )
    }
  },
  {
    path: "/gerenciador/classes/:id",
    name: "ClassesEdit",
    meta: {
      requiresAuth: true
    },
    components: {
      header: AdminHeader,
      default: () =>
        import(
          /* webpackChunkName: "classesEdit" */ "../views/admin/classes/Form.vue"
        )
    }
  },
  {
    path: "/gerenciador/configuracoes",
    name: "Settings",
    meta: {
      requiresAuth: true
    },
    components: {
      header: AdminHeader,
      default: () =>
        import(
          /* webpackChunkName: "Settings" */ "../views/admin/settings/Index.vue"
        )
    },
    children: [
      {
        path: "/gerenciador/configuracoes/linguas",
        name: "Languages",
        meta: {
          requiresAuth: true
        },
        component: () =>
          import(
            /* webpackChunkName: "SettingsLanguage" */ "../views/admin/settings/languages/Index.vue"
          )
      },

      {
        path: "/gerenciador/configuracoes/periodos",
        name: "Periods",
        meta: {
          requiresAuth: true
        },
        component: () =>
          import(
            /* webpackChunkName: "SettingsPeriods" */ "../views/admin/settings/periods/Index.vue"
          )
      },

      {
        path: "/gerenciador/configuracoes/fases",
        name: "Phases",
        meta: {
          requiresAuth: true
        },
        component: () =>
          import(
            /* webpackChunkName: "SettingsPhases" */ "../views/admin/settings/phases/Index.vue"
          )
      },

      {
        path: "/gerenciador/configuracoes/correlatos",
        name: "Correlates",
        meta: {
          requiresAuth: true
        },
        component: () =>
          import(
            /* webpackChunkName: "SettingsCorrelates" */ "../views/admin/settings/correlates/Index.vue"
          )
      }
    ]
  },

  {
    path: "/ten-classes",
    name: "ten-classes",
    meta: {
      requiresAuth: false
    },
    components: {
      sidemenu: SideMenu,
      default: () =>
        import(
          /* webpackChunkName: "pages" */ "../views/Diagrams/TenClasses.vue"
        )
    }
  },

  {
    path: "/sixty-six-classes",
    name: "sixty-six-classes",
    meta: {
      requiresAuth: false
    },
    components: {
      sidemenu: SideMenu,
      default: () =>
        import(
          /* webpackChunkName: "pages" */ "../views/Diagrams/SixtySixClasses.vue"
        )
    }
  },

  {
    path: "/glossary",
    name: "glossary",
    meta: {
      requiresAuth: false
    },
    components: {
      sidemenu: SideMenu,
      default: () =>
        import(/* webpackChunkName: "pages" */ "../views/Glossary.vue")
    }
  },

  {
    path: "/:id",
    name: "Pages",
    meta: {
      requiresAuth: false
    },
    components: {
      sidemenu: SideMenu,
      default: () => import(/* webpackChunkName: "pages" */ "../views/Page.vue")
    }
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

router.beforeEach((to, from, next) => {
  Auth.onAuthStateChanged(function(user) {
    if (
      to.matched.some(record => record.meta.requiresAuth) &&
      to.path != "/gerenciador/login"
    ) {
      !user
        ? next({
            path: "/gerenciador/login",
            query: { redirect: to.fullPath }
          })
        : next();
    } else if (to.path == "/gerenciador/login") {
      user
        ? next({
            path: "/gerenciador/painel"
          })
        : next();
    } else {
      next();
    }
  });
});

export default router;
