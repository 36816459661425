<template>
  <div id="app" class="bg-gray-100 h-screen overflow-auto p-0 m-0">
    <router-view name="header" />

    <div class="flex justify-center">
      <router-view name="sidemenu" />
      <router-view />
    </div>

    <router-view name="footer" />

    <transition name="slideSidebar">
      <ClassTab v-if="selectedClass" :classId="selectedClass" />
    </transition>

    <GlossaryModal v-if="selectedEntry.entryId" />
  </div>
</template>

<script>
import { mapState } from "vuex";
import GlossaryModal from "@/components/GlossaryModal";
import ClassTab from "@/components/ClassTab";

export default {
  components: {
    GlossaryModal,
    ClassTab
  },
  computed: {
    ...mapState({
      selectedEntry: state => state.Glossary.selectedEntry,
      selectedClass: state => state.Classes.active
    })
    // ...mapGetters({
    //   item: "namespace/item"
    // })
  },
  async created() {
    await this.$store.dispatch("Settings/list");
    await this.$store.dispatch("Glossary/list");
  },
  watch: {
    $route() {
      if (this.$route.query.classSign) {
        this.$store.commit("Classes/SET_ACTIVE", this.$route.query.classSign);
      }
    }
  }
};
</script>

<style lang="scss">
.slideSidebar-enter-active,
.slideSidebar-leave-active {
  transition: all 0.3s ease;
}
.slideSidebar-enter,
.slideSidebar-leave-to {
  opacity: 0;
}

a.download-pdf {
  position: absolute;
  bottom: 5px;
  right: 20px;
  background: #2b2b2b;
  color: #fff;
  text-decoration: none;
  padding: 0.5rem 1rem;
  border-radius: 5px;

  &:hover {
    background-color: #000;
  }
}
</style>
