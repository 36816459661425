import { Storage } from "@/services/firebase";
import dayjs from "dayjs";

const ImageStore = {
  namespaced: true,
  state: {
    list: [],
    single: {}
  },
  mutations: {
    SET_LIST(state, payload) {
      state.list = payload;
    },
    SET_SINGLE(state, payload) {
      state.single = payload;
    }
  },
  actions: {
    async uploadImage(context, data) {
      const extension = data.file.name.substring(
        data.file.name.lastIndexOf(".") + 1
      );
      const filename = data.file.name.substr(
        0,
        data.file.name.lastIndexOf(".")
      );
      const newFileName = `${filename}-${dayjs().unix()}.${extension}`;

      console.log(filename, extension, newFileName);
      const storageRef = Storage.ref();
      const response = await storageRef
        .child(`${data.folder}/${newFileName}`)
        .put(data.file);
      const url = await response.ref.getDownloadURL();
      return url;
    }
  }
};

export default ImageStore;
