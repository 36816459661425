import Classes from "@/api/classes";
import dayjs from "dayjs";

const ClassesStore = {
  namespaced: true,
  state: {
    list: [],
    active: null
  },
  mutations: {
    SET_LIST(state, payload) {
      state.list = payload;
    },
    SET_ACTIVE(state, payload) {
      state.active = payload;
    }
  },
  actions: {
    async list({ commit }) {
      try {
        const response = await Classes.list();
        commit("SET_LIST", response);
        return response;
      } catch (err) {
        console.log(err);
      }
    },

    async fromList(_, list) {
      try {
        const response = await Classes.fromList(list);
        return response;
      } catch (err) {
        console.log(err);
      }
    },

    async single(_, docId) {
      try {
        const response = await Classes.get(docId);

        response.startingDate = dayjs
          .unix(response.startingDate / 1000)
          .format();

        response.endingDate = dayjs.unix(response.endingDate / 1000).format();
        return response;
      } catch (err) {
        console.log(err);
      }
    },

    async langs(_, docId) {
      try {
        const response = await Classes.child(docId);
        return response;
      } catch (err) {
        console.log(err);
      }
    },

    async add({ dispatch }, [signData, langsData]) {
      try {
        const response = await Classes.add(signData, langsData);

        if (response._status) {
          await dispatch("list");
        }
        return response;
      } catch (err) {
        console.log(err);
      }
    },

    async update({ dispatch }, [signData, langsData]) {
      try {
        const response = await Classes.update(signData, langsData);

        if (response._status) {
          await dispatch("list");
        }

        return response;
      } catch (err) {
        console.log(err);
      }
    },

    async remove({ dispatch }, ClassesId) {
      try {
        const response = await Classes.remove(ClassesId);

        if (response._status) {
          await dispatch("list");
        }

        return response;
      } catch (err) {
        console.log(err);
      }
    }
  }
};

export default ClassesStore;
