import Settings from "@/api/settings";

const SettingsStore = {
  namespaced: true,
  state: {
    languages: [],
    periods: [],
    phases: [],
    activeLanguage: "en",
    maintenance: false,
    c1: null,
    c2: null,
    c3: null
  },
  mutations: {
    SET_LANGUAGES(state, payload) {
      state.languages = payload;
    },
    SET_PERIODS(state, payload) {
      state.periods = payload;
    },
    SET_PHASES(state, payload) {
      state.phases = payload;
    },
    SET_ACTIVE_LANG(state, payload) {
      state.activeLanguage = payload;
    },
    SET_MAINTENANCE(state, payload) {
      state.maintenance = payload;
    },
    SET_C1(state, payload) {
      state.c1 = payload;
    },
    SET_C2(state, payload) {
      state.c2 = payload;
    },
    SET_C3(state, payload) {
      state.c3 = payload;
    }
  },
  actions: {
    async list({ commit }) {
      try {
        const response = await Settings.list();

        const languages = response.find(item => item.languages);
        const periods = response.find(item => item.periods);
        const phases = response.find(item => item.phases);
        const maintenance = response.find(item => item.maintenance);
        const c1 = response.find(item => item.c1);
        const c2 = response.find(item => item.c2);
        const c3 = response.find(item => item.c3);

        const currentLanguage = localStorage.getItem("minse_language");
        const setLang = currentLanguage
          ? JSON.parse(currentLanguage)
          : {
              abbreviation: "en",
              title: "English"
            };

        if (languages) {
          commit("SET_LANGUAGES", languages.languages);
          commit("SET_PERIODS", periods.periods);
          commit("SET_PHASES", phases.phases);
          commit("SET_ACTIVE_LANG", setLang);
          commit("SET_C1", c1.c1);
          commit("SET_C2", c2.c2);
          commit("SET_C3", c3.c3);
        }

        if (maintenance) {
          commit("SET_MAINTENANCE", maintenance.maintenance);
        }
        return response;
      } catch (err) {
        console.log(err);
      }
    },

    async update({ dispatch }, [itemId, itemData]) {
      try {
        const response = await Settings.update(itemId, itemData);

        if (response) {
          await dispatch("list");
        }
        return response;
      } catch (err) {
        console.log(err);
      }
    }
  }
};

export default SettingsStore;
