import Pages from "@/api/pages";
// import dayjs from "dayjs";

const PagesStore = {
  namespaced: true,
  state: {
    list: []
  },
  mutations: {
    SET_LIST(state, payload) {
      state.list = payload;
    }
  },
  actions: {
    async list({ commit }) {
      try {
        const response = await Pages.list();
        commit("SET_LIST", response);
        return response;
      } catch (err) {
        console.log(err);
      }
    },

    async single(_, docId) {
      try {
        const response = await Pages.get(docId);
        return response;
      } catch (err) {
        console.log(err);
      }
    },

    async langs(_, docId) {
      try {
        const response = await Pages.child(docId);
        return response;
      } catch (err) {
        console.log(err);
      }
    },

    async add({ dispatch }, [pageData, langsData]) {
      try {
        const response = await Pages.add(pageData, langsData);

        if (response._status) {
          await dispatch("list");
        }
        return response;
      } catch (err) {
        console.log(err);
      }
    },

    async update({ dispatch }, [pageData, langsData]) {
      try {
        const response = await Pages.update(pageData, langsData);

        if (response._status) {
          await dispatch("list");
        }

        return response;
      } catch (err) {
        console.log(err);
      }
    },

    async remove({ dispatch }, PagesId) {
      try {
        const response = await Pages.remove(PagesId);

        if (response._status) {
          await dispatch("list");
        }

        return response;
      } catch (err) {
        console.log(err);
      }
    }
  }
};

export default PagesStore;
