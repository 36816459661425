import { Auth } from "@/services/firebase.js";

const _baseStore = {
  namespaced: true,
  state: {},
  mutations: {},
  actions: {
    async Login(_, data) {
      try {
        const Login = await Auth.signInWithEmailAndPassword(
          data.email,
          data.password
        );
        return {
          success: true,
          content: Login
        };
      } catch (err) {
        console.log("store/User.js Login(): ", err);
        return {
          success: false,
          content: err.message
        };
      }
    },

    async SignOut() {
      try {
        const signOut = await Auth.signOut();
        if (signOut) {
          return true;
        } else {
          return false;
        }
      } catch (err) {
        console.log("store/User.js SignOut(): ", err);
        return false;
      }
    }
  }
};

export default _baseStore;
