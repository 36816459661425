<template>
  <div class="w-full pl-8">
    <div class="flex">
      <span class="block text-xs font-bold" style="width: 8.09%">
        Oi
      </span>
      <span class="block text-xs font-bold" style="width: 8.09%">
        Ii
      </span>
      <span class="block text-xs font-bold" style="width: 8.09%">
        S
      </span>
      <span class="block text-xs font-bold" style="width: 8.09%">
        Od
      </span>
      <span class="block text-xs font-bold" style="width: 8.09%">
        Id
      </span>
      <span class="block text-xs font-bold" style="width: 8.09%">
        If
      </span>
      <span class="block text-xs font-bold" style="width: 9.09%">
        S-Od
      </span>
      <span class="block text-xs font-bold" style="width: 9.09%">
        S-Id
      </span>
      <span class="block text-xs font-bold" style="width: 8.09%">
        S-If
      </span>
      <span class="block text-xs font-bold text-center" style="width: 12.59%">
        S-Od-Id
      </span>
      <span class="block text-xs font-bold text-center" style="width: 11.59%">
        S-Od-If
      </span>
    </div>

    <div class="flex mb-4">
      <span class="block text-xs" style="width: 8.09%">
        {{ this.sign.eleven.io }}
      </span>
      <span class="block text-xs" style="width: 8.09%">
        {{ this.sign.eleven.ii }}
      </span>
      <span class="block text-xs" style="width: 8.09%">
        {{ this.sign.eleven.s }}
      </span>
      <span class="block text-xs" style="width: 8.09%">
        {{ this.sign.eleven.do }}
      </span>
      <span class="block text-xs" style="width: 8.09%">
        {{ this.sign.eleven.di }}
      </span>
      <span class="block text-xs" style="width: 8.09%">
        {{ this.sign.eleven.fi }}
      </span>
      <span class="block text-xs pl-2" style="width: 9.09%">
        {{ this.sign.eleven.sdo }}
      </span>
      <span class="block text-xs pl-2" style="width: 9.09%">
        {{ this.sign.eleven.sdi }}
      </span>
      <span class="block text-xs pl-2" style="width: 8.09%">
        {{ this.sign.eleven.sfi }}
      </span>
      <span class="block text-xs text-center" style="width: 12.59%">
        {{ this.sign.eleven.sdodi }}
      </span>
      <span class="block text-xs text-center" style="width: 11.59%">
        {{ this.sign.eleven.sdofi }}
      </span>
    </div>

    <div
      class="h-4 rounded w-full bg-gray-300 mb-4"
      :style="`background: linear-gradient(90deg, ${tricotomyColor});`"
    ></div>

    <div class="relative w-full mb-2">
      <div v-if="!editable" class="absolute pleft">1C</div>
      <div class="w-full">
        <vue-slider
          v-model="setC1"
          :marks="marks"
          :adsorb="true"
          :tooltip="'none'"
          :included="true"
          :enable-cross="false"
          :hideLabel="true"
          :process-style="{
            backgroundColor: correlatesColor[sign.thricotomy.s]
          }"
          :disabled="!editable"
          class="w-full"
        >
          <template v-slot:dot="{ index }">
            <div
              class="relative"
              :class="index == 0 ? 'start-dot' : 'end-dot'"
              :style="
                index == 0
                  ? `background-color: ${correlatesColor[sign.thricotomy.s]}`
                  : `border-left-color: ${correlatesColor[sign.thricotomy.s]};`
              "
            >
              <div
                v-if="index == 1"
                class="absolute pright px-1 text-white font-bold rounded"
                :style="
                  `background-color: ${correlatesColor[sign.thricotomy.s]}`
                "
              >
                {{ sign.thricotomy.s }}
              </div>
            </div>
          </template>
        </vue-slider>
      </div>
    </div>

    <div class="relative w-full mb-2">
      <div v-if="!editable" class="absolute pleft">2C</div>
      <div class="w-full">
        <vue-slider
          v-model="setC2"
          :adsorb="true"
          :marks="marks"
          :included="true"
          :tooltip="'none'"
          :enable-cross="false"
          :hideLabel="true"
          :process-style="{
            backgroundColor: correlatesColor[sign.thricotomy.o]
          }"
          :disabled="!editable"
        >
          <template v-slot:dot="{ index }">
            <div
              class="relative"
              :class="index == 0 ? 'start-dot' : 'end-dot'"
              :style="
                index == 0
                  ? `background-color: ${correlatesColor[sign.thricotomy.o]}`
                  : `border-left-color: ${correlatesColor[sign.thricotomy.o]};`
              "
            >
              <div
                v-if="index == 1"
                class="absolute pright px-1 text-white font-bold rounded"
                :style="
                  `background-color: ${correlatesColor[sign.thricotomy.o]}`
                "
              >
                {{ sign.thricotomy.o }}
              </div>
            </div>
          </template>
        </vue-slider>
      </div>
    </div>

    <div class="relative w-full mb-2">
      <div v-if="!editable" class="absolute pleft">3C</div>
      <div class="w-full">
        <vue-slider
          v-model="setC3"
          :marks="marks"
          :included="true"
          :adsorb="true"
          :tooltip="'none'"
          :enable-cross="false"
          :hideLabel="true"
          :process-style="{
            backgroundColor: correlatesColor[sign.thricotomy.i]
          }"
          :disabled="!editable"
        >
          <template v-slot:dot="{ index }">
            <div
              class="relative"
              :class="index == 0 ? 'start-dot' : 'end-dot'"
              :style="
                index == 0
                  ? `background-color: ${correlatesColor[sign.thricotomy.i]}`
                  : `border-left-color: ${correlatesColor[sign.thricotomy.i]};`
              "
            >
              <div
                v-if="index == 1"
                class="absolute pright px-1 text-white font-bold rounded"
                :style="
                  `background-color: ${correlatesColor[sign.thricotomy.i]}`
                "
              >
                {{ sign.thricotomy.i }}
              </div>
            </div>
          </template>
        </vue-slider>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import labels from "@/i18n/labels";

import VueSlider from "vue-slider-component";
import "vue-slider-component/theme/default.css";

export default {
  name: "ElevenTrichotomies",
  components: {
    VueSlider
  },
  filters: {},
  props: {
    sign: Object,
    editable: {
      type: Boolean,
      default: true
    }
  },
  data: function() {
    return {
      C1: [0, 0],
      C2: [0, 0],
      C3: [0, 0],

      marks: {
        "0": "IO",
        "9": "II",
        "17": "S",
        "25": "DO",
        "33": "DI",
        "41": "FI",
        "50": "S-DO",
        "59": "S-DI",
        "69": "S-FI",
        "82": "S-DO-DI",
        "94": "S-DO-FI",
        "100": ""
      },

      revMarks: {
        IO: "0",
        II: "9",
        S: "17",
        DO: "25",
        DI: "33",
        FI: "41",
        "S-DO": "50",
        "S-DI": "59",
        "S-FI": "69",
        "S-DO-DI": "82",
        "S-DO-FI": "94"
      },

      correlatesColor: {
        "1": "rgba(205, 51, 51, 1)",
        "1'": "rgba(204, 102, 1, 1)",
        "2": "rgba(255, 204, 51, 1)",
        '1"': "rgba(101, 51, 102, 1)",
        "2'": "rgba(52, 102, 51, 1)",
        "3": "rgba(51, 103, 153, 1)"
      }
    };
  },
  computed: {
    ...mapState({
      lang: state => state.Settings.activeLanguage.abbreviation
    }),
    // ...mapGetters({
    //   item: "namespace/item"
    // }),

    labels() {
      return labels[this.lang] ? labels[this.lang] : labels.en;
    },

    setC1: {
      get() {
        return [this.revMarks[this.C1[0]], this.revMarks[this.C1[1]]];
      },

      set(value) {
        this.C1 = [
          this.marks[value[0].toString()],
          this.marks[value[1].toString()]
        ];
      }
    },

    setC2: {
      get() {
        return [this.revMarks[this.C2[0]], this.revMarks[this.C2[1]]];
      },

      set(value) {
        this.C2 = [
          this.marks[value[0].toString()],
          this.marks[value[1].toString()]
        ];
      }
    },

    setC3: {
      get() {
        return [this.revMarks[this.C3[0]], this.revMarks[this.C3[1]]];
      },

      set(value) {
        this.C3 = [
          this.marks[value[0].toString()],
          this.marks[value[1].toString()]
        ];
      }
    },

    tricotomyColor() {
      const colorByNumber = {
        "1": "rgba(205, 51, 51, 1)",
        "2": "rgba(255, 204, 51, 1)",
        "3": "rgba(51, 103, 153, 1)"
      };
      const colorByEleven = {
        io: `${colorByNumber[this.sign.eleven.io]} 0%`,
        ii: `${colorByNumber[this.sign.eleven.ii]} 9.09%`,
        s: `${colorByNumber[this.sign.eleven.s]} 18.18%`,
        do: `${colorByNumber[this.sign.eleven.do]} 27.27%`,
        di: `${colorByNumber[this.sign.eleven.di]} 36.36%`,
        fi: `${colorByNumber[this.sign.eleven.fi]} 45.45%`,
        sdo: `${colorByNumber[this.sign.eleven.sdo]} 54.54%`,
        sdi: `${colorByNumber[this.sign.eleven.sdi]} 63.63%`,
        sfi: `${colorByNumber[this.sign.eleven.sfi]} 71.72%`,
        sdodi: `${colorByNumber[this.sign.eleven.sdodi]} 82.81%`,
        sdofi: `${colorByNumber[this.sign.eleven.sdofi]} 100%`
      };

      return `${colorByEleven.io}, ${colorByEleven.ii}, ${colorByEleven.s}, ${colorByEleven.do}, ${colorByEleven.di}, ${colorByEleven.fi}, ${colorByEleven.sdo}, ${colorByEleven.sdi}, ${colorByEleven.sfi}, ${colorByEleven.sdodi}, ${colorByEleven.sdofi}`;
    },

    fixedSlider() {
      const C1startIndex = this.C1[0] != 0 ? this.data.indexOf(this.C1[0]) : 0;
      const C1endIndex = this.C1[1] != 0 ? this.data.indexOf(this.C1[1]) : 0;

      const C2startIndex = this.C2[0] != 0 ? this.data.indexOf(this.C2[0]) : 0;
      const C2endIndex = this.C2[1] != 0 ? this.data.indexOf(this.C2[1]) : 0;

      const C3startIndex = this.C3[0] != 0 ? this.data.indexOf(this.C3[0]) : 0;
      const C3endIndex = this.C3[1] != 0 ? this.data.indexOf(this.C3[1]) : 0;

      return {
        C1: {
          start: this.marks[C1startIndex],
          end: this.marks[C1endIndex],
          range: this.marks[C1endIndex] - this.marks[C1startIndex]
        },
        C2: {
          start: this.marks[C2startIndex],
          end: this.marks[C2endIndex],
          range: this.marks[C2endIndex] - this.marks[C2startIndex]
        },
        C3: {
          start: this.marks[C3startIndex],
          end: this.marks[C3endIndex],
          range: this.marks[C3endIndex] - this.marks[C3startIndex]
        }
      };
    }
  },
  watch: {
    C1() {
      if (this.editable) this.$emit("setC1", this.C1);
    },
    C2() {
      if (this.editable) this.$emit("setC2", this.C2);
    },
    C3() {
      if (this.editable) this.$emit("setC3", this.C3);
    }
  },
  async created() {},
  async mounted() {
    if (this.sign && this.sign.visualization) {
      this.C1 =
        this.sign.visualization.C1 && this.sign.visualization.C1.length == 2
          ? this.sign.visualization.C1
          : [0, 0];
      this.C2 =
        this.sign.visualization.C2 && this.sign.visualization.C2.length == 2
          ? this.sign.visualization.C2
          : [0, 0];
      this.C3 =
        this.sign.visualization.C3 && this.sign.visualization.C3.length == 2
          ? this.sign.visualization.C3
          : [0, 0];
    }
  },
  methods: {}
};
</script>

<style scoped lang="scss">
.start-dot {
  width: 100%;
  height: 100%;
  border-radius: 0%;
}

.end-dot {
  width: 0;
  height: 0;
  border-top: 7px solid transparent;
  border-bottom: 7px solid transparent;
  border-left: 15px solid green;
}

.pleft {
  left: -2rem;
}
</style>

<style lang="scss">
.vue-slider-disabled {
  opacity: 1 !important;
  cursor: default !important;

  .vue-slider-rail {
    background: none !important;
  }
}

.vue-slider-mark {
  display: none !important;
}

.pright {
  right: 1.1rem;
  top: -12px;
}
</style>
