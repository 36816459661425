import { Database } from "@/services/firebase";
// import dayjs from "dayjs";

export default {
  async list() {
    try {
      const querySnapshot = await Database.collection("settings").get();
      const list = [];
      querySnapshot.forEach(function(doc) {
        const docObj = {
          ...doc.data()
        };
        list.push(docObj);
      });
      return list;
    } catch (err) {
      return {
        _status: false,
        _message: "Error api/settings.js: " + err
      };
    }
  },

  async update(dataId, data) {
    try {
      const docRef = await Database.collection("settings").doc(dataId);
      docRef.set({
        ...data
      });
      return {
        _status: true,
        _message: "Document updated."
      };
    } catch (err) {
      return {
        _status: false,
        _message: "Error api/settings.js: " + err
      };
    }
  }
};
