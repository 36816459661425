<template>
  <div class="fixed right-0 top-0 h-screen w-screen z-50 shadow-lg">
    <div
      @click.prevent="goBack()"
      class="tab-bg fixed top-0 right-0 w-screen h-screen"
    ></div>

    <div
      class="fixed top-0 right-0 bg-white w-full xl:w-3/4 h-screen overflow-auto p-5 lg:p-10"
    >
      <div
        @click.prevent="print()"
        class="border px-3 pt-2 cursor-pointer absolute top-0 left-0 m-6 hover:bg-gray-100"
      >
        <img src="/icons/print.svg" class="w-6 h-8" alt="" />
      </div>

      <div
        @click.prevent="goBack()"
        class="border px-3 py-2 cursor-pointer absolute top-0 right-0 m-6"
      >
        <img src="/icons/close.svg" class="w-6 h-6" alt="" />
      </div>

      <div v-if="sign" class="mx-auto w-full max-w-5xl">
        <header class="flex items-center pt-10">
          <div id="signTile">
            <ClassSign
              :sign="sign"
              :c1="c1"
              :c2="c2"
              :c3="c3"
              :lang="lang"
              class="shadow-lg"
            />
          </div>
          <div class="ml-4">
            <h4>{{ labels.class }} {{ sign.number }}</h4>
            <h1 class="text-3xl font-bold mt-1 leading-tight">
              {{ sign.langs[lang] }}
            </h1>
          </div>
        </header>

        <section class="my-10">
          <div class="flex">
            <h3 class="text-lg mt-1 w-1/2">
              {{ labels.period }}:
              <strong>{{ periodTitle }}</strong>
            </h3>
            <h3 class="text-lg mt-1 w-1/2">
              {{ labels.phase }}:
              <strong>{{ phaseTitle }}</strong>
            </h3>
          </div>

          <div class="md:flex mt-10 classSign">
            <div class="w-full md:w-1/2 md:pr-4 lg:pr-10">
              <p
                class="text-xl mb-6"
                v-html="langs[lang] ? langs[lang].description : ''"
              ></p>

              <p
                class="text-xl"
                v-html="langs[lang] ? langs[lang].example : ''"
              ></p>
            </div>

            <div id="solenoid" class="w-full md:w-1/2">
              <SolenoidComponent
                class="w-full"
                :sign="sign"
                :showSlider="showSlider"
              ></SolenoidComponent>
              <ElevenTrichotomies :sign="sign" :editable="false" />
            </div>
          </div>
        </section>

        <HowQuote
          :title="sign.langs[lang]"
          :updateDate="sign._updateDate ? sign._updateDate : sign._createDate"
        />
      </div>
      <div v-else class="flex h-full w-full items-center justify-center">
        <DotLoading dotColor="#25899b" />
      </div>
    </div>

    <canvas
      id="canvasTile"
      width="128"
      height="128"
      class="hidden fixed bottom-0 right-0"
    ></canvas>
    <canvas
      id="canvasSolenoid"
      width="512"
      height="696"
      class="hidden fixed bottom-0 right-0"
    ></canvas>
  </div>
</template>

<script>
import { mapState } from "vuex";
import labels from "@/i18n/labels";
import html2canvas from "html2canvas";

import ClassSign from "@/components/ClassSign";
import DotLoading from "@/components/DotLoading";
import SolenoidComponent from "@/components/SolenoidComponent";
import ElevenTrichotomies from "@/components/ElevenTrichotomies";
import HowQuote from "@/components/HowQuote";

export default {
  name: "ClassTab",
  components: {
    ClassSign,
    DotLoading,
    SolenoidComponent,
    ElevenTrichotomies,
    HowQuote
  },
  filters: {},
  props: {},
  data: function() {
    return {
      loading: true,
      fromRoute: null,
      sign: null,
      langs: null,
      showSlider: true
    };
  },
  computed: {
    ...mapState({
      lang: state => state.Settings.activeLanguage.abbreviation,
      periods: state => state.Settings.periods,
      phases: state => state.Settings.phases,
      glossary: state => state.Glossary.list,
      c1: state => state.Settings.c1,
      c2: state => state.Settings.c2,
      c3: state => state.Settings.c3
    }),
    labels() {
      return labels[this.lang] ? labels[this.lang] : labels["en"];
    },
    periodTitle() {
      if (this.periods[this.sign.period]) {
        return this.periods[this.sign.period][this.lang]
          ? this.periods[this.sign.period][this.lang].title
          : this.periods[this.sign.period]["en"].title;
      } else return "";
    },
    phaseTitle() {
      if (this.phases[this.sign.phasing]) {
        return this.phases[this.sign.phasing][this.lang]
          ? this.phases[this.sign.phasing][this.lang].title
          : this.phases[this.sign.phasing]["en"].title;
      } else return "";
    }
    // ...mapGetters({
    //   item: "namespace/item"
    // })
  },
  watch: {},
  async created() {
    const id = this.$route.query.classSign;
    const sign = await this.$store.dispatch("Classes/single", id);
    const langs = await this.$store.dispatch("Classes/langs", id);

    this.sign = sign;
    this.langs = langs;

    // this.applyGlossary();
    this.loading = false;
  },
  async mounted() {},
  methods: {
    goBack() {
      this.$router.push({
        path: this.$route.path
      });
      this.$store.commit("Classes/SET_ACTIVE", null);
    },

    async print() {
      this.showSlider = false;

      setTimeout(async () => {
        try {
          const solenoidHtml = document.getElementById("solenoid");
          const tileHtml = document.getElementById("signTile");

          const canvasTile = document.getElementById("canvasTile");
          const canvasSolenoid = document.getElementById("canvasSolenoid");

          await html2canvas(tileHtml, { canvas: canvasTile });
          await html2canvas(solenoidHtml, { canvas: canvasSolenoid });

          var tileImg = canvasTile.toDataURL("image/png");
          var solenoidImg = canvasSolenoid.toDataURL("image/png");

          this.showSlider = true;

          /* Change MIME type to trick the browser to downlaod the file instead of displaying it */
          // img = img.replace(
          //   /^data:image\/[^;]*/,
          //   "data:application/octet-stream"
          // );

          // /* In addition to <a>'s "download" attribute, you can define HTTP-style headers */
          // img = img.replace(
          //   /^data:application\/octet-stream/,
          //   "data:application/octet-stream;headers=Content-Disposition%3A%20attachment%3B%20filename=Canvas.png"
          // );

          const content = this.pdfHtml({ tileImg, solenoidImg });

          var newWindow = window.open();
          newWindow.document.write(content);
          newWindow.onload = function() {
            newWindow.print();
          };
        } catch (err) {
          console.log({ err });
          alert(
            "Ops, nao foi possível gerar o conteúdo dessa classe. Tente novamente mais tarde."
          );
        }
      }, 150);
    },

    pdfHtml({ tileImg, solenoidImg }) {
      return `<!DOCTYPE html>
<html lang="en">

<head>
  <meta charset="UTF-8">
  <meta http-equiv="X-UA-Compatible" content="IE=edge">
  <meta name="viewport" content="width=device-width, initial-scale=1.0">
  <title>${this.sign.number} - ${this.sign.langs[this.lang]}</title>

  <style>
    * {
      font-family: Arial, Helvetica, sans-serif;
      font-size: 10pt;
      margin: 0;
      padding: 0;
    }

    table {
      max-width: 1200px;
      margin: 0 auto;
    }

    table,
    table tr,
    table td {
      margin: 0;
      padding: 0;
      border: none;
    }

    @media print{
      table {
        max-width: initial;
      }
    }

  </style>
</head>

<body>
  <table class="main-table no-break" width="100%">
    <tbody>
      <tr>
        <td width="128px">
          <img src="${tileImg}" style="width: 128px;">
        </td>

        <td style="padding: 0.5cm;">
          <h3>${this.labels.class} ${this.sign.number}</h3>
          <h1>${this.sign.langs[this.lang]}</h1>
        </td>
      </tr>
    </tbody>
  </table>

  <table class="main-table no-break" width="100%">
    <tbody>
      <tr>
        <td width="50%" style="padding: 0.25cm 0.5cm; font-size: 16pt !important; vertical-align: top;">
          <p class="text-lg mt-1 w-1/2">
            ${this.labels.period}:
            <strong>${this.sign.langs[this.lang]}</strong>
          </p>
        </td>
        <td width="50%" style="padding: 0.25cm 0.5cm; font-size: 16pt !important; vertical-align: top;">
          <p class="text-lg mt-1 w-1/2">
            ${this.labels.phase}:
            <strong>${this.phaseTitle}</strong>
          </p>  
        </td>  
      </tr>

      <tr>
        <td width="50%" style="padding: 0.25cm 0.5cm; font-size: 16pt !important; vertical-align: top;">
          ${this.langs[this.lang] ? this.langs[this.lang].description : ""}
          <br>
          ${this.langs[this.lang] ? this.langs[this.lang].example : ""}
        </td>
        <td width="50%" style="padding: 0.25cm 0.5cm; font-size: 16pt !important; vertical-align: top;">
          <img src="${solenoidImg}" style="width: 100%;">  
        </td>  
      </tr>
    </tbody>
  </table>
</body>
</html>
			`;
    }
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      vm.fromRoute = from;
    });
  }
};
</script>

<style scoped lang="scss">
.tab-bg {
  background: rgba(0, 0, 0, 0.75);
}
</style>

<style lang="scss">
.classSign {
  h1 {
    font-size: 24px;
    font-weight: 700;
    margin-bottom: 0.6rem;
    line-height: 42px;
  }

  h2 {
    font-size: 20px;
    font-weight: 700;
    margin-bottom: 0.6rem;
  }

  h3 {
    font-size: 18px;
    font-weight: 700;
    margin-bottom: 0.6rem;
  }

  p {
    font-size: 1.4rem;
    margin-bottom: 1rem;
  }

  hr {
    margin: 2rem 0;
    border-color: #ccc;
  }

  img {
    margin: 0.5rem 0;
  }

  h1 + p,
  h2 + p,
  h3 + p,
  h4 + p,
  h5 + p,
  blockquote p,
  ul li p,
  ol li p {
    text-indent: 0;
  }

  blockquote {
    padding: 1rem 0 1rem 4rem;
    font-size: 1.3rem;

    p {
      font-size: 1.3rem;
    }
  }

  ul {
    list-style: circle;
    text-indent: 0;
    margin: 2rem 0 2rem 3.8rem;
    padding: 0;

    li {
      font-family: Georgia, Times, "Times New Roman", serif;
      font-size: 1.4rem;
    }
  }

  ol {
    list-style: decimal;
    text-indent: 0;
    margin: 2rem 0 2rem 3.8rem;
    padding: 0;

    li {
      font-family: Georgia, Times, "Times New Roman", serif;
      font-size: 1.4rem;
    }
  }

  table {
    width: calc(90% - 4rem);
    margin: 2rem 0 2rem 4rem;
  }

  table,
  tr,
  td,
  th {
    border: 1px solid #ccc;
    padding: 6px;

    p {
      font-size: 1rem;
      text-indent: 0;
    }
  }

  th,
  th p {
    font-family: "Poppins", sans-serif;
    text-align: left;
  }
}
</style>
