<template functional>
  <div
    v-if="props.sign && typeof props.sign == 'object'"
    class="rounded relative classSign m-1 flex-shrink-0 flex transition-shadow cursor-pointer items-center justify-center"
    :style="`background-color: ${props.sign.color};`"
    @click.prevent="listeners.click"
  >
    <span class="absolute top-0 left-0 text-xs p-1">
      <span class="pl-1">{{ props.sign.thricotomy.s }}</span>
      <span class="pl-1">{{ props.sign.thricotomy.o }}</span>
      <span class="pl-1">{{ props.sign.thricotomy.i }}</span>
    </span>

    <span class="absolute top-0 right-0 text-xs p-1">{{
      props.sign.number
    }}</span>

    <span
      class="[ bottomElement ] absolute w-full flex justify-center bottom-0 left-0 right-0 text-xs p-1"
    >
      <span class="pr-1">
        {{ props.sign.eleven.io }}{{ props.sign.eleven.ii
        }}{{ props.sign.eleven.s }}{{ props.sign.eleven.do
        }}{{ props.sign.eleven.di }}{{ props.sign.eleven.fi }}
      </span>
      <span class="pr-1"
        >{{ props.sign.eleven.sdo }}{{ props.sign.eleven.sdi
        }}{{ props.sign.eleven.sfi }}</span
      >
      <span>{{ props.sign.eleven.sdodi }}{{ props.sign.eleven.sdofi }}</span>
    </span>

    <span class="flex flex-col items-center text-sm font-bold lowercase -mt-2">
      <span>{{ props.c1[props.sign.c1][props.lang].title }}</span>
      <span>{{ props.c2[props.sign.c2][props.lang].title }}</span>
      <span>{{ props.c3[props.sign.c3][props.lang].title }}</span>
    </span>
  </div>

  <div
    v-else
    class="rounded p-1 relative classSign m-1 pb-3 flex-shrink-0 flex cursor-pointer items-center justify-center"
    style="background-color: transparent"
  ></div>
</template>

<script>
export default {
  name: "ClassSign",
  components: {},
  filters: {},
  props: {
    sign: Object,
    c1: Object,
    c2: Object,
    c3: Object,
    lang: String
  },
  data: function() {
    return {};
  },
  computed: {},
  watch: {},
  async created() {},
  async mounted() {},
  methods: {}
};
</script>

<style scoped lang="scss">
.classSign {
  width: 120px;
  height: 120px;
  line-height: 1.5 !important;
}

.top-0 {
  top: -2px;
}

.bottomElement {
  bottom: 4px;
}
</style>
