export default {
  es: {
    minute: "Semiótica",
    semiotic: "Minuta",
    tables: "Tablas de clases",
    table10: "Diez divisiones de signos de Peirce",
    table66: "Tabla periódica de clases de signos",
    glossary: "Glosario",
    institutional: "Otras informaciones"
  },

  it: {
    minute: "Semiotica",
    semiotic: "Minuta",
    tables: "Tavole delle classi",
    table10: "Dieci divisioni di segni di Peirce",
    table66: "Tavola periodica delle classi di segni",
    glossary: "Glossario",
    institutional: "Altre informazioni"
  },

  fr: {
    minute: "Semiotique",
    semiotic: "Minute",
    tables: "Tableau des classes",
    table10: "Tableau des dix classes de signes",
    table66: "Tableau périodique des classes de signes",
    glossary: "Glossaire",
    institutional: "Autres renseignements"
  },

  en: {
    minute: "Minute",
    semiotic: "Semiotic",
    tables: "Tables of classes",
    table10: "Peirce’s 10 classes of signs",
    table66: "Periodic Table of Classes of Signs",
    glossary: "Glossary",
    institutional: "Other information",
    class: "Class",
    classes: "Classes",
    period: "Period",
    phase: "Phase",
    correlate: "Correlate",
    elevenTrichotomies: "Eleven trichotomies",
    grounding: "Grounding",
    presentation: "Presentation",
    representation: "Representation",
    communication: "Communication",
    perceiving: "Perceiving",
    experiencing: "Experiencing",
    understanding: "Understanding",
    sharing: "Sharing",
    semeioticWheel: "Semeiotic wheel",
    description: "Description",
    example: "Example",
    io: "IO",
    ii: "II",
    s: "S",
    do: "DO",
    di: "DI",
    fi: "FI",
    sdo: "S-DO",
    sdi: "S-DI",
    sfi: "S-FI",
    sdodi: "S-DO-DI",
    sdofi: "S-DO-FI"
  },

  pt: {
    minute: "Semiótica",
    semiotic: "Minuta",
    tables: "Tabelas de classes",
    table10: "As 10 classes de signo",
    table66: "Tabela Periódica das Classes de Signos",
    glossary: "Glossário",
    institutional: "Mais informações",
    class: "Classe",
    classes: "Classes",
    period: "Período",
    phase: "Fase",
    correlate: "Correlato",
    elevenTrichotomies: "Onze Tricotomias",
    grounding: "Fundamentação",
    presentation: "Presentação",
    representation: "Representação",
    communication: "Comunicação",
    perceiving: "Percebendo",
    experiencing: "Experimentando",
    understanding: "Compreendendo",
    sharing: "Compartilhando",
    semeioticWheel: "Disco Semiótico",
    description: "Descrição",
    example: "Exemplo",
    io: "OI",
    ii: "II",
    s: "S",
    do: "OD",
    di: "ID",
    fi: "IF",
    sdo: "S-OD",
    sdi: "S-ID",
    sfi: "S-IF",
    sdodi: "S-OD-ID",
    sdofi: "S-OD-IF"
  }
};
