<template>
  <div v-if="lang.abbreviation == 'pt'" class="bg-gray-200 rounded p-6 my-10">
    <p style="text-indent: 0; font-size: 18px;" class="text-sm text-gray-600">
      Como citar (ABNT):
    </p>
    <p style="text-indent: 0; font-size: 18px;">
      ROMANINI, Vinicius. {{ title }}. <strong>Minute Semeiotic</strong>,
      {{ year }}. Disponível em: &lt;www.minutesemeiotic.org&gt;. Acesso em:
      {{ today }}.
    </p>
  </div>

  <div v-else class="bg-gray-200 rounded p-6 my-10">
    <p style="text-indent: 0; font-size: 18px;" class="text-sm text-gray-600">
      Quote this:
    </p>
    <p style="text-indent: 0; margin-bottom: 0;" class="text-sm text-gray-600">
      MLA:
    </p>
    <p style="text-indent: 0; font-size: 18px; margin-bottom: 1rem">
      Romanini, Vinicius. “{{ title }}” <em>Minute Semeiotic</em>.
      www.minutesemeiotic.org. Accessed {{ today }}.
    </p>

    <p style="text-indent: 0; margin-bottom: 0;" class="text-sm text-gray-600">
      APA:
    </p>
    <p style="text-indent: 0; font-size: 18px; margin-bottom: 1rem;">
      Romanini, V. ({{ year }}) “{{ title }}” <em>Minute Semeiotic</em>.
      Retrieved {{ todayAPA }}, from www.minutesemeiotic.org.
    </p>

    <p style="text-indent: 0; margin-bottom: 0;" class="text-sm text-gray-600">
      Chicago:
    </p>
    <p style="text-indent: 0; font-size: 18px; margin-bottom: 1rem">
      Romanini, Vinicius. “{{ title }}” <em>Minute Semeiotic</em>. Acessed
      {{ todayAPA }}. www.minutesemeiotic.org.
    </p>
  </div>
</template>

<script>
import { mapState } from "vuex";
import dayjs from "dayjs";
import "dayjs/locale/pt-br";
import "dayjs/locale/en";

export default {
  name: "HowQuote",
  components: {},
  filters: {},
  props: {
    title: String,
    updateDate: String
  },
  data: function() {
    return {};
  },
  computed: {
    ...mapState({
      lang: state => state.Settings.activeLanguage
    }),

    locale() {
      if (this.lang.abbreviation == "pt") return "pt-br";
      else return "en";
    },

    year() {
      return dayjs(this.updateDate)
        .locale(this.locale)
        .format("YYYY");
    },

    today() {
      return dayjs()
        .locale(this.locale)
        .format("DD MMMM YYYY");
    },

    todayAPA() {
      return dayjs()
        .locale(this.locale)
        .format("MMMM DD, YYYY");
    }
  },
  watch: {},
  async created() {},
  async mounted() {},
  methods: {}
};
</script>

<style scoped lang="scss"></style>
